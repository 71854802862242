import React from 'react'
import { styles } from '../Theme'
import { Layout, Footer, Button, Container, Text } from '../components'
import { Link } from 'gatsby'
import ErrorLogo from '../images/error-image.svg'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <StyledContainer>
        <ImageContainer>
          <ImageError src={ErrorLogo} />
        </ImageContainer>
        <StyledTitle color={styles.colors.darkGrey}>{t('error:title')}</StyledTitle>
        <Link to='/'>
          <Button>{t('error:button-text')}</Button>
        </Link>
      </StyledContainer>
      <Footer />
    </Layout>
  )
}

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 189px;
`

const ImageError = styled.img`
  margin-bottom: 25px;
  width: 100%;
`
const StyledTitle = styled(Text)`
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 31px;
  @media (min-width: 768px) {
    margin-bottom: 32px;
  }
`

const ImageContainer = styled.div`
  display: flex;
`

export default NotFoundPage
